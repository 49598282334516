import CryptoJS from 'crypto-js'
//加密
let publicKey='asdfqazwsxedcrfv'

//公钥加密
export function getCode(passwd){
  let key  = CryptoJS.enc.Utf8.parse(publicKey);
	let srcs = CryptoJS.enc.Utf8.parse(passwd);
	let encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
	return encrypted.toString();
  }
  
  export function decrypt (passwd) {
    var key = CryptoJS.enc.Utf8.parse(publicKey)
    var decrypt = CryptoJS.AES.decrypt(passwd, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }